import { useTranslation } from "react-i18next";

export const useStatusMap = () => {
  const { t } = useTranslation();

  return {
    Active: { color: "green", label: t("status.active") },
    Approved: { color: "blue", label: t("status.approved") },
    "Awaiting Approval": { color: "orange", label: t("status.awaiting_approval") },
    "Awaiting for approval": { color: "orange", label: t("status.awaiting_approval") },
    Billed: { color: "blue", label: t("status.approved") },
    Cancelled: { color: "red", label: t("status.cancelled") },
    Completed: { color: "blue", label: t("status.completed") },
    Draft: { color: "orange", label: t("status.draft") },
    Failed: { color: "red", label: t("status.failed") },
    Inactive: { color: "default", label: t("status.inactive") },
    Overdue: { color: "orange", label: t("status.overdue") },
    Paid: { color: "green", label: t("status.paid") },
    "Partially Paid": { color: "orange", label: t("status.partially_paid") },
    Scheduled: { color: "blue", label: t("status.scheduled") },
    Skipped: { color: "orange", label: t("status.skipped") },
    Success: { color: "green", label: t("status.success") },
  };
};
